.fc {
  width: 100%;
  padding: 15px 40px !important;
  min-width: 80vw;
  min-height: 80vh;
}

.fc-toolbar-title {
  font-size: 20px !important;
}

@media (max-width: 960px) {
  .fc {
    min-width: 90vw;
    min-height: 90vh;
    padding: 0px !important;
  }
}
.daypicker {
  width: 100%;
}

.horarioCheckBox {
  padding-top: 10px;

  padding-left: 15px;
  padding-right: 10px;
  width: 100%;
  border-radius: 5%;
}

.horarioDisponible {
  border-width: 2px;
  border-style: solid;
  border-color: green;
}

.horarioNoDisponible {
  border-width: 2px;
  border-style: solid;
  border-color: red;
}

.horarioDeshabilitado {
  border-width: 2px;
  border-style: solid;
  border-color: gray;
}

.buttonContainer .tg-btn {
  margin-top: 5px;
  margin-left: 5px;
}
.buttonRight {
  float: right;
}
@media (max-width: 600px) {
  .auth-header {
    font-size: 18px !important;
  }
  .titlePadel {
    font-size: 18px !important;
  }
}

@media (max-width: 960px) {
  .buttonContainer {
    text-align: center;
  }
  .buttonContainer .tg-btn {
    margin-top: 15px;
    margin-left: 0;
  }
  .buttonRight {
    float: left;
  }
}

.ant-row-flex {
  max-width: 80vw !important;
}

.scheduler-view {
  max-width: 80%;
}

.rbc-events-container {
  display: grid;
  grid-template-rows: repeat(24, 40px);
}

.rbc-day-slot .rbc-event {
  position: static;
}
.rbc-day-slot .rbc-event:hover {
  color: white;
}

.rbc-day-slot .rbc-event--2 {
  background-color: #fbab18;
}

.rbc-day-slot .rbc-event--3 {
  background-color: red;
}

.rbc-day-slot .rbc-event--1 {
  background-color: #6ebe4a;
}
/* .rbc-calendar {
  max-height: 70vh !important;
} */
/* .rbc-timeslot-group {
  padding: 35px 0px;
}
 */

.pc-box {
  display: flex;
  justify-content: space-between;
}

.page-link {
  font-size: 18px !important;
}
