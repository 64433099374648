.DayPicker-Caption > div {
  font-weight: 600 !important;
  font-size: 18px !important;
}

.DayPicker-Weekday abbr[title] {
  font-size: 18px !important;
  padding: 8px !important;
}

.DayPicker-Day {
  font-size: 18px !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #8dbe22 !important;
}

.DayPicker-NavButton {
  width: 2em;
  height: 2em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8b9898;
  cursor: pointer;
}
